import { jwtDecode } from "jwt-decode";

export const TOKEN_KEY = "access-token";
export const PERMISSIONS_KEY = "permissions";
export const MODULES_KEY = "modules";

type User = {
  id: number;
  name: string;
  email: string;
  level: number;
  image: string;
  permissions: string[];
};

type Modules = {
  id: number;
  image: string;
  name: string;
  description: string;
  url: string;
};

export const getModules = (): Modules[] => {
  const modules = localStorage.getItem(MODULES_KEY);

  if (modules === null) {
    return [];
  }

  return JSON.parse(modules).filter(
    (module) => !module.name.includes("Autenticador")
  );
};

export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getPermission = () => localStorage.getItem(PERMISSIONS_KEY);

export const getTokenInfo = (): User | null => {
  const accessToken = getToken();
  if (accessToken === null) {
    return null;
  }
  try {
    let result = jwtDecode(accessToken, { header: false }) as User;
    return result;
  } catch (err) {
    return null;
  }
};

export const decodeToken = (): any => {
  const accessToken = getToken();
  if (accessToken === null) {
    return null;
  }

  const user = jwtDecode(accessToken, { header: false }) as User;
  return user;
};

export const verifyPermission = (permission): boolean => {
  let _permissions = getPermission();

  if (_permissions === null) {
    return false;
  }

  const permissions = JSON.parse(_permissions) as String[];

  return permissions.some((_permission) => _permission === permission);
};

export const isAuthenticated = async () => {
  const token = getToken();
  if (token === null) {
    return false;
  }
  const decoded = await getTokenInfo();
  if (!decoded) {
    return false;
  }
  return true;
};

export const login = (token: string) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const setAccess = (modules: string, permissions: string) => {
  localStorage.setItem(PERMISSIONS_KEY, permissions);
  localStorage.setItem(MODULES_KEY, modules);
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};
